import './index.sass';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {toNumber} from "uikit/src/js/util";

// loads the Icon plugin
UIkit.use(Icons);


window.jQuery = $;
window.$ = $;




// components can be called from the imported UIkit reference
//UIkit.notification('Hello world.');


$( '.slider_read-more a' ).click(function() {

  document.location.href = $(this).attr('href');
});

$('.offcanvas-push ul').each(function(index){
    let i  = $(this).parents('ul').length;
    $(this).attr('data-level',i)
})
//some element selector and a click event...plain js works here tooooo
$('.offcanvas-push ul ul').each(function(e){
  $(this).prepend('<span class="next-ul"><span class="uk-icon" uk-icon="chevron-right"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" stroke-width="1.03" points="7 4 13 10 7 16"></polyline></svg></span></span>')
})

$( ".offcanvas-push" ).on( "click", ".prev-ul", function() {

   let levelId = $(this).closest('ul').data('level') - 1
    if(levelId < 1) {
        $('.uk-list-1').css('display','');
    }
   $('*[data-level="'+levelId+'"]').children('li').css('display',  '');
   let o =  $(this).closest('.uk-nav').css('margin-left')
   let i = 0
   i = parseInt(o) +  290


    $(this).closest('.uk-nav').animate({
        marginLeft: i+'px',
    }, 100);

   $(this).remove()

})
$(".offcanvas-push div > ul").prepend('<li class="uk-text-center prev-ul-top">Меню</li>');
$( ".offcanvas-push" ).on( "click", ".next-ul", function() {
  $('.uk-list-1').css('display','none');
  let l = $(this).offset().left
  let o =  $(this).closest('.uk-nav').css('margin-left')
  let i = 0
   if(parseInt(o) == 0) {
    i = parseInt(o) - l - 90
   }
   else {
    i = parseInt(o) - l  - 60
   }
  let linkPrev =  $(this).closest('li').children('a')
  $(this).parent('ul').prepend('<li class="uk-text-center prev-ul"><span class="uk-icon" uk-icon="chevron-left"></span>'+linkPrev.text()+'</li>');

  //$(this).closest('.uk-nav').css('margin-left',  i+'px')

    let th =   $(this);

    $(this).closest('.uk-nav').animate({
        marginLeft: i+'px',
    }, 100, function() {

    });
    th.closest('li').siblings('li').css('display',  'none');
});

$('.catalog-left-menu > ul > li > ul > li > a').each(function(e){
    $(this).prepend('<span class="uk-icon op" uk-icon="icon: chevron-down; ratio: 0.7"></span>')
})



$('.catalog-left-menu > ul > li > ul > li > ul > li > a').each(function(e){
    $(this).append('<span class="uk-icon on" uk-icon="icon: chevron-right; ratio: 0.7"></span>')
})
$('.catalog-left-menu   > ul > li > ul > .active  > a > .uk-icon').remove();
$('.catalog-left-menu   > ul > li > ul > .active  >  a').prepend('<span class="uk-icon op" uk-icon="icon: chevron-up; ratio: 0.7"></span>');

$( ".catalog-left-menu" ).on( "click", ".op", function() {
    if($(this).closest('li').children('ul').css('display') == 'none') {
        $(this).html('<span class="uk-icon op" uk-icon="icon: chevron-up; ratio: 0.7"></span>')
        $(this).closest('li').children('ul').css('display', 'block')
    }
    else {
        $(this).html('<span class="uk-icon op" uk-icon="icon: chevron-down; ratio: 0.7"></span>')
        $(this).closest('li').children('ul').css('display', 'none')
    }
    return false;
})




    // List of Video Vendors embeds you want to support
    let players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

    // Select videos
    let fitVids = document.querySelectorAll(players.join(","));

    // If there are videos on the page...
    if (fitVids.length) {
        // Loop through videos
        for (let i = 0; i < fitVids.length; i++) {
            // Get Video Information
            let fitVid = fitVids[i];
            let width = fitVid.getAttribute("width");
            let height = fitVid.getAttribute("height");
            let aspectRatio = height / width;
            let parentDiv = fitVid.parentNode;

            // Wrap it in a DIV
            let div = document.createElement("div");
            div.className = "fitVids-wrapper";
            div.style.paddingBottom = aspectRatio * 100 + "%";
            parentDiv.insertBefore(div, fitVid);
            fitVid.remove();
            div.appendChild(fitVid);

            // Clear height/width from fitVid
            fitVid.removeAttribute("height");
            fitVid.removeAttribute("width");
        }
        $('.fitVids-wrapper').closest('p').addClass('fitVids-wrapper-p');
    }
